import { create } from "zustand";
import { persist } from "zustand/middleware";

interface LeadPassenger {
  title: string;
  first_name: string | null;
  last_name: string | null;
  type: string;
}

interface Room {
  id: string;
  adults: number;
  children: number;
  children_ages: number[];
  lead_passenger: LeadPassenger;
}

export interface SearchQueryStore {
  locationQuery: {
    id?: string;
    name?: string;
    type?: string;
  };
  checkInDate: Date;
  checkOutDate: Date;
  totalAdults: number;
  totalChildren: number;
  providerKey: string;
  dealKey: string;
  hotelId: string;
  bookingRoom: any;
  billingInformation: any;
  rooms: Room[];
  setLocationQuery: (locationQueryId: string, locationName: string, type: string) => void;
  setDatesQuery: (checkInDate: string, checkOutDate: string) => void;
  setRoomsQuery: (rooms: Room[]) => void;
  addAdults: (roomId: string) => void;
  removeAdults: (roomId: string) => void;
  addChildren: (roomId: string) => void;
  setChildren: (ageValue: number, childIndex: number, roomId: string) => void;
  removeChildren: (roomId: string) => void;
  addRoom: () => void;
  removeRoom: (roomId: string) => void;
  setLeadPassenger: (firstName: string, lastName: string) => void;
  setHotelId: (hotelId: string) => void;
  setProviderKey: (provider: string) => void;
  setDealKey: (deal: string) => void;
  setBookingRoom: (roomObject: any) => void;
  setBillingInformation: (data: any) => void;
  setTotalAdults: (totalAdults: number) => void;
  setTotalChildren: (totalChildren: number) => void;
}

export const useSearchQueryStore = create<SearchQueryStore>()(
  persist(
    (set, get) => ({
      locationQuery: {},
      checkInDate: new Date(),
      checkOutDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      totalAdults: 1,
      totalChildren: 0,
      providerKey: "",
      dealKey: "",
      hotelId: "",
      bookingRoom: {},
      billingInformation: {},
      rooms: [
        {
          id: "r0",
          adults: 1,
          children: 0,
          children_ages: [],
          lead_passenger: {
            title: "Mr./Ms.",
            first_name: null,
            last_name: null,
            type: "adult",
          },
        },
      ],

      setLocationQuery: (
        locationQueryId: string,
        locationName: string,
        type: string
      ) => {
        set(() => ({
          locationQuery: { id: locationQueryId, name: locationName, type },
        }));
      },
      setDatesQuery: (checkInDate: string, checkOutDate: string) => {
        const checkInDateObj = new Date(checkInDate);
        const checkOutDateObj = new Date(checkOutDate);
        set(() => ({
          checkInDate: checkInDateObj,
          checkOutDate: checkOutDateObj,
        }));
      },      
      setRoomsQuery: (rooms: Room[]) => set(() => ({ rooms: rooms })),
      addAdults: (roomId: string) => {
        const rooms = get().rooms;
        rooms.find((room) => {
          if (room.id === roomId) {
            room.adults += 1;
            set((state) => ({ totalAdults: +state.totalAdults + 1 }));
          }
        });
        set(() => ({ rooms: rooms }));
      },
      removeAdults: (roomId: string) => {
        const rooms = get().rooms;
        rooms.find((room) => {
          if (room.id === roomId) {
            room.adults -= 1;
            set((state) => ({ totalAdults: +state.totalAdults - 1 }));
          }
        });
        set(() => ({ rooms: rooms }));
      },
      addChildren: (roomId: string) => {
        const rooms = get().rooms;

        rooms.find((room) => {
          if (room.id === roomId) {
            room.children_ages.push(2);
            room.children += 1;
            set((state) => ({ totalChildren: +state.totalChildren + 1 }));
          }
        });
        set(() => ({ rooms: rooms }));
      },
      setChildren: (ageValue: number, childIndex: number, roomId: string) => {
        const rooms = get().rooms;
        rooms.forEach((room) => {
          if (room.id === roomId) {
            room.children_ages[childIndex] = Number(ageValue);
          }
        });
        set(() => ({ rooms: rooms }));
      },
      removeChildren: (roomId: string) => {
        const rooms = get().rooms;
        rooms.find((room) => {
          if (room.id === roomId) {
            room.children_ages.pop();
            room.children -= 1;
            set((state) => ({
              totalChildren: +state.totalChildren - 1,
            }));
          }
        });
        set(() => ({ rooms: rooms }));
      },
      addRoom: () => {
        const rooms = get().rooms;
        rooms.push({
          id: Math.random().toString(36),
          adults: 1,
          children: 0,
          children_ages: [],
          lead_passenger: {
            title: "Mr./Ms.",
            first_name: null,
            last_name: null,
            type: "adult",
          },
        });
        set((state) => ({
          rooms: rooms,
          totalAdults: +state.totalAdults + 1,
        }));
      },
      removeRoom: (roomId: string) => {
        const rooms = get().rooms;
        const updatedRooms = rooms.filter((room) => {
          if (room.id !== roomId) return true;
          else {
            set((state) => ({
              totalAdults: +state.totalAdults - +room.adults,
              totalChildren: +state.totalChildren - +room.children_ages.length,
            }));
            room.children_ages = [];

            return false;
          }
        });
        set(() => ({
          rooms: updatedRooms,
        }));
      },
      setLeadPassenger: (firstName: string, lastName: string) => {
        const rooms = window.sessionStorage.getItem("roomsGuests");
        if (!rooms) return;
        const parsedRooms = JSON.parse(rooms);

        const newRooms = parsedRooms.map((room: Room) => {
          return {
            ...room,
            lead_passenger: {
              first_name: firstName,
              last_name: lastName,
              title: "Mr./Ms.",
              type: "adult",
            },
          };
        });
        window.sessionStorage.setItem("roomsGuests", JSON.stringify(newRooms));
       },
      setHotelId: (hotelId: string) => {
        set(() => ({ hotelId: hotelId }));
      },
      setProviderKey: (provider: string) => {
        set(() => ({ providerKey: provider }));
      },
      setDealKey: (deal: string) => {
        set(() => ({ dealKey: deal }));
      },
      setBookingRoom: (roomObject: any) => {
        set(() => ({ bookingRoom: roomObject }));
      },
      setBillingInformation: (data: any) => {
        set(() => ({ billingInformation: data }));
      },
      setTotalAdults: (totalAdults: number) => {
        set(() => ({ totalAdults: totalAdults }));
      },
      setTotalChildren: (totalChildren: number) => {
        set(() => ({ totalChildren: totalChildren }));
      },
    }),
    { name: "SearchQuery" }
  )
);
