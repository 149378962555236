import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { IoMdPeople } from "react-icons/io";
import { useSearchQueryStore } from "store/useSearchQueryStore";
import RoomsSelect from "./roomsSelect";
function RoomsCard() {
  const {
    setChildren,
    addChildren,
    removeChildren,
    removeAdults,
    addAdults,
    rooms,
    totalAdults,
    totalChildren,
    addRoom,
    removeRoom,
  }: any = useSearchQueryStore();

  const [showSelect, setShowSelect] = useState(false);
  const [roomState, setRoomState] = useState(null);
  const roomsDivRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useRouter();
  const { t: translate } = useTranslation();
  const { locale } = useRouter();

  const adult_plural =
    totalAdults > 1
      ? `${translate("searchBar.Adults")}`
      : `${translate("searchBar.Adult")}`;
  const child_plural =
    totalChildren > 1
      ? `${translate("searchBar.Children")}`
      : `${translate("searchBar.Child")}`;
  const room_plural =
    rooms.length > 1
      ? `${translate("searchBar.Rooms")}`
      : `${translate("searchBar.Room")}`;

  useEffect(() => {
    setRoomState(rooms);
  }, []);

  //* Hide the list on clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        roomsDivRef.current &&
        !roomsDivRef.current.contains(event.target as Node)
      ) {
        setShowSelect(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [roomsDivRef]);
  return (
    <>
      <div
        className={`flex-grow-0 shadow-xl relative p-4 bg-white  shadow-neutral md:rounded-lg ${
          pathname === "/"
            ? "lg:rounded-full p-4"
            : "lg:rounded-3xl h-16 p-3 px-3"
        } `}
      >
        <div className="flex gap-2">
          <IoMdPeople className="opacity-50" size={24} />
          <div
            onClick={() => setShowSelect((prevState) => !prevState)}
            className="cursor-pointer"
          >
            <h3 className="text-xs opacity-50">
              {translate("searchBar.Guests")}
            </h3>
            {roomState && (
              <h3 className="mt-2 text-xs ">
                {`${
                  locale === "en"
                    ? rooms.length
                    : rooms.length.toLocaleString("ar-EG")
                }    ${room_plural}  ,     ${
                  locale === "en"
                    ? totalAdults
                    : totalAdults.toLocaleString("ar-EG")
                }    ${adult_plural} ,         ${
                  locale === "en"
                    ? totalChildren
                    : totalChildren.toLocaleString("ar-EG")
                }    ${child_plural}`}
              </h3>
            )}
          </div>
        </div>

        <div className={`${showSelect ? "block" : "hidden"}`} ref={roomsDivRef}>
          {roomState && (
            <RoomsSelect
              rooms={rooms}
              setChildren={setChildren}
              addChildren={addChildren}
              removeChildren={removeChildren}
              addRoom={addRoom}
              removeRoom={removeRoom}
              addAdults={addAdults}
              removeAdults={removeAdults}
              totalAdults={totalAdults}
              totalChildren={totalChildren}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default RoomsCard;
