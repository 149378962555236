import { useTheme } from "hooks/useTheme";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";

interface Props {
  className?: string;
  size?: number;
  customColor?: string;
}

function Loader({ className = "", size = 25, customColor }: Props) {
  const { themeColors } = useTheme();
  const [loaderColor, setloaderColor] = useState(themeColors.primary);

  useEffect(() => {
    setloaderColor(themeColors?.primary ?? "#0081DF");
  }, [themeColors]);

  return (
    <BeatLoader
      className={className}
      color={customColor ?? loaderColor}
      size={size}
    />
  );
}

export default Loader;
