import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as locales from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import moment from "moment";

import { useTheme } from "hooks/useTheme";

function DatePicker({
  locale,
  setDatesQuery,
  currentWidth,
  state,
  setState,
}: any) {
  const { themeColors } = useTheme();

  const afterOneYear = () => {
    return moment().add(1, "year").toDate();
  };

  const disableTodayAfter8pm = () => {
    const todayAt8pm = moment().set({
      hour: 20,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const now = moment();
    if (now.isAfter(todayAt8pm)) {
      todayAt8pm.add(1, "days");
      return todayAt8pm.toDate();
    }
    return new Date();
  };
  return (
    <DateRange
      editableDateInputs={false}
      showMonthAndYearPickers={false}
      onChange={(item) => {
        setState([item.selection]);
        setDatesQuery(item.selection.startDate, item.selection.endDate);
      }}
      minDate={disableTodayAfter8pm()}
      maxDate={afterOneYear()}
      locale={locale == "ar" ? locales["arSA"] : locales["enGB"]}
      dragSelectionEnabled={false}
      months={currentWidth > 800 ? 2 : 1}
      showPreview={true}
      moveRangeOnFirstSelection={false}
      ranges={state}
      className="date-picker"
      direction="horizontal"
      showDateDisplay={false}
      rangeColors={[themeColors.primary]}
      dayDisplayFormat="dd"
    />
  );
}

export default DatePicker;
