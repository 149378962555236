import { useTranslation } from "next-i18next";
import { AiOutlineCloseCircle } from "react-icons/ai";

const MAX_ADULTS = 6;
const MIN_ADULTS = 1;
const MAX_CHILDREN = 3;
const MIN_CHILDREN = 0;

function RoomsPeople({
  rooms,
  removeRoom,
  addAdults,
  setChildren,
  removeAdults,
  addChildren,
  removeChildren,
}: any) {
  const { t: translate } = useTranslation();

  // Generate options for children select
  const childrenSelectOption: any = [];
  for (let i = 2; i < 12; i++) {
    childrenSelectOption.push(
      <option
        key={`o-${i}`}
        value={i}
        className="item-center"
        disabled={i === 0 ? true : false}
      >
        {i === 0 ? translate("searchBar.Select Age") : i}
      </option>
    );
  }

  return rooms.map((room: any, index: number) => {
    return (
      <div key={room.id} className="mb-2">
        <div className="flex gap-3 mb-3 border-b rounded-none">
          <h3 className="mb-3 text-xs">
            {translate("searchBar.Room")} {index + 1}{" "}
          </h3>
          {index > 0 && (
            <div className="flex gap-1">
              <AiOutlineCloseCircle size={16} className="text-error" />
              <h3
                onClick={() => removeRoom(room.id)}
                className="text-xs font-normal lowercase cursor-pointer hover:underline text-error"
              >
                {translate("searchBar.Remove")}
              </h3>
            </div>
          )}
        </div>

        <div className="flex justify-between mb-1 gap-7">
          <h3 className="text-xs">{translate("searchBar.Adults")}</h3>
          <div className="grid grid-cols-3 gap-3">
            <button
              onClick={() => removeAdults(room.id)}
              className="w-8 h-8 text-xl text-white bg-gray-300 rounded-full btn btn-sm"
              disabled={room.adults === MIN_ADULTS}
            >
              -
            </button>
            <h3 className="p-0 mx-2 mt-1 text-center">{room.adults}</h3>
            <button
              onClick={() => addAdults(room.id)}
              className="w-8 h-8 text-xl text-white rounded-full btn btn-sm btn-primary"
              disabled={room.adults === MAX_ADULTS}
            >
              +
            </button>
          </div>
        </div>
        <div className="flex justify-between gap-4 py-3">
          <h3 className="text-xs">{translate("searchBar.Children")}</h3>
          <div className="grid grid-cols-3 gap-3">
            <button
              onClick={() => removeChildren(room.id)}
              className="w-8 h-8 text-xl text-white bg-gray-300 rounded-full btn btn-sm"
              disabled={room.children_ages.length === MIN_CHILDREN}
            >
              -
            </button>

            <h3 className="p-0 mx-2 mt-1 text-center">
              {room.children_ages.length}
            </h3>
            <button
              onClick={() => addChildren(room.id)}
              className="w-8 h-8 text-xl text-white rounded-full btn btn-sm btn-primary"
              disabled={room.children_ages.length === MAX_CHILDREN}
            >
              +
            </button>
          </div>
        </div>
        <div className="mt-2 mb-3">
          {room.children_ages?.map((child: any, index: number) => {
            return (
              <div
                key={`c-${room.id + index}`}
                className="flex justify-between"
              >
                <label className="mt-2 text-xs text-slate-500">
                  {translate("searchBar.Child")} {index + 1}{" "}
                  {translate("searchBar.Age")}
                </label>
                <select
                  onChange={(event) => {
                    setChildren(event.target.value, index, room.id);
                  }}
                  defaultValue={child}
                  className="w-3/6 h-10 max-w-xs mb-2 text-center rounded-lg bg-neutral border-slate-300 select-xs select-ghost"
                >
                  {childrenSelectOption}
                </select>
              </div>
            );
          })}
        </div>
      </div>
    );
  });
}

export default RoomsPeople;
