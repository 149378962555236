//import { RoomState } from "hooks/types";
import { useRef, useEffect } from "react";
import RoomsPeople from "./roomsPeople";
import { useTranslation } from "next-i18next";

function RoomsSelect({
  rooms,
  addRoom,
  setChildren,
  addChildren,
  removeChildren,
  addAdults,
  removeAdults,
  removeRoom,
}: any) {
  const scrollBottom = useRef<HTMLDivElement | null>(null);
  const { t: translate } = useTranslation();

  useEffect(() => {
    scrollBottom.current?.scrollIntoView({ behavior: "smooth" });
  }, [rooms.length]);

  return (
    <>
      <div className="absolute left-0 z-40 w-full p-5 mt-6 overflow-y-scroll bg-white rounded-lg shadow-xl shadow-neutral max-h-64">
        <RoomsPeople
          rooms={rooms}
          addChildren={addChildren}
          removeChildren={removeChildren}
          addAdults={addAdults}
          removeAdults={removeAdults}
          removeRoom={removeRoom}
          setChildren={setChildren}
          addRoom={addRoom}
        />
        <div className="flex justify-center ">
          <button
            onClick={addRoom}
            className="text-xs font-normal text-white normal-case btn btn-success btn-sm btn-outline"
            disabled={rooms.length > 2 ? true : false}
          >
            {translate("searchBar.Add new room")}
          </button>
        </div>
      </div>
    </>
  );
}

export default RoomsSelect;
